import { useAppContext } from '@pagegrowler_nextjs';
import { PagegrowlerBackendInstance } from '@pagegrowler_nextjs_ssr';

export async function getServerSideProps({ req, res }) {
    let pagegrowlerBackend = PagegrowlerBackendInstance();
    let context = {
        query: { match: [''] }
    }

    const pagegrowlerFrontendApiUrl = process.env.NEXT_PUBLIC_PAGEGROWLER_FRONTEND_API;
    pagegrowlerBackend.setPagegrowlerFrontendApiUrl(pagegrowlerFrontendApiUrl);

    const result = await pagegrowlerBackend.handleGetServerSideProps(context, false);
    if (!result.notFound) {
        res.setHeader('Last-Modified', result.props.matchConfig.landing.lastModified);
    }
    return result;
}

const Match = ({ matchConfig }) => {
    let Pagegrowler = useAppContext().pagegrowler;
    return Pagegrowler.handleMatch(matchConfig);
}

export default Match;